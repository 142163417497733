import React from 'react'
import "../Styles/faculty.css"
import { motion } from 'framer-motion'
import Team from "../Images/Faculty.jpg"
import Team1 from "../Images/Faculty1.jpg"
import Team2 from "../Images/Faculty2.png"
import Team3 from "../Images/Faculty3.png"
import Team4 from "../Images/Faculty4.png"
import Team5 from "../Images/Faculty5.jpg"
import Team6 from "../Images/Faculty6.jpg"
import Team7 from "../Images/Faculty7.jpg"
import Team8 from "../Images/Faculty8.jpg"
import Team9 from "../Images/Faculty9.jpg"
import Team10 from "../Images/Faculty10.jpg";
import Team11 from "../Images/Faculty11.jpg";

export default function Faculty() {
    return (
        <>
            <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 py-12">
                <div className="text-center pb-12">
                    <motion.h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900" style={{ fontFamily: "Poppins" }}>
                        Our Faculty
                    </motion.h1>
                    <motion.h2 className="font-bold text-indigo-600 py-3" style={{ fontFamily: "Poppins" }}>
                        Our passionate educators are dedicated to providing<br /> a nurturing environment that fosters creativity, curiosity, and a love for learning.
                    </motion.h2>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <TeamTile name={""} img={""} designation={""} />
                    <TeamTile name={"Mrs. Naina Ved"} img={Team} designation={"Principal"} />
                    <TeamTile name={""} img={""} designation={""} />
                    <TeamTile name={"Mr. Manish Chauhan"} img={Team2} designation={"Administrator"} />
                    <TeamTile name={"Mrs. Anita Chauhan"} img={Team4} designation={"Class Teacher"} />
                    <TeamTile name={"Mrs. Seema Patel"} img={Team3} designation={"Coordinator"} />
                    <TeamTile name={""} img={""} designation={""} />
                    <TeamTile name={"Ms. Bhakti Gandhi"} img={Team11} designation={"Student Counselor"} />
                    <TeamTile name={""} img={""} designation={""} />
                </div>
            </section>
            <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 py-12">
                <div className="text-center pb-12">
                    <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900" style={{ fontFamily: "Poppins" }}>
                        Members,<br style={{ fontSize: "60px" }} /> Shree Delhi Gujarati&nbsp;Mahila Samaj
                    </h1>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <TeamTile name={"Mrs. Jyoti Parikh"} img={Team1} designation={"President"} />
                    <TeamTile name={"Mrs. Namrata Wadhwana"} img={Team6} designation={"Vice - President"} />
                    <TeamTile name={"Mrs. Kirti Desai Vora"} img={Team7} designation={"General Secretary"} />
                    <TeamTile name={"Mrs. Panna Hemani"} img={Team5} designation={"Trustee"} />
                    <TeamTile name={"Mrs. Hemlata Shah"} img={Team8} designation={"Trustee"} />
                    <TeamTile name={"Mrs. Rekha Parikh"} img={Team9} designation={"Trustee"} />
                </div>
            </section>
            <motion.section className="text-gray-600 body-font">
                <motion.div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <motion.div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                        <motion.img initial={{ opacity: 0, x: -15 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.9 }} className="object-cover object-center rounded" alt="" style={{ borderRadius: "50px" }} src={Team10} height={"500px"} width={"600px"} />
                    </motion.div>
                    <motion.div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                        <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.9, delay: 0.6 }} className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Devoted team of faculty who remain well wishers of Shishu Mangal School</motion.h1>
                        <motion.p className="mb-8 leading-relaxed"></motion.p>
                    </motion.div>
                </motion.div>
            </motion.section>
        </>
    )
}

const TeamTile = ({ name, img, designation }) => {
    return (
        <div className="w-full bg-white rounded-lg p-5 flex flex-col justify-center items-center">
            <motion.img whileHover={{ scale: 1.15 }} initial={{ opacity: 0, y: -2 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }} transition={{ duration: 0.9 }} className="object-center object-cover rounded-full w-40 h-40" height={"390px"} width={"390px"} src={img} alt="" />
            <div className="text-center py-4" style={{ fontFamily: "Lemonada", fontWeight: "800" }}>
                <motion.p viewport={{ once: true }} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.5 }} className="text-xl text-gray-700 mb-2">{name}</motion.p>
                <motion.p viewport={{ once: true }} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.5 }} className="text-base text-gray-400 font-normal">{designation}</motion.p>
            </div>
        </div>
    )
}
