import "../Styles/slideshow.css"
import Img25 from "../Images/ptm.jpg"
import { motion } from 'framer-motion'
import Img26 from "../Images/ptm1.jpg"
import Img27 from "../Images/ptm2.jpg"
import Img28 from "../Images/ptm3.jpg"
import Img29 from "../Images/ptm4.jpg"
import Img30 from "../Images/ptm5.jpg"
import Img31 from "../Images/fest.jpg"
import Img32 from "../Images/fest1.jpg"
import Img33 from "../Images/fest2.jpg"
import Img34 from "../Images/fest3.jpg"
import Img35 from "../Images/fest4.jpg"
import Img36 from "../Images/fest5.jpg"
import Img from "../Images/Activity.jpg"
import Img1 from "../Images/Activity1.jpg"
import Img2 from "../Images/Activity2.jpg"
import Img3 from "../Images/Activity3.jpg"
import Img4 from "../Images/Activity4.jpg"
import Img5 from "../Images/Activity5.jpg"
import Img15 from "../Images/carnival.jpg"
import Img12 from "../Images/hidenseek.jpg"
import Img16 from "../Images/carnival1.jpg"
import Img17 from "../Images/carnival2.jpg"
import Img18 from "../Images/carnival3.jpg"
import Img19 from "../Images/carnival4.jpg"
import Img20 from "../Images/carnival5.jpg"
import Img21 from "../Images/sportsday.jpg"
import Img13 from "../Images/hidenseek1.jpg"
import Img14 from "../Images/hidenseek2.jpg"
import Img6 from "../Images/VanMahotsav.jpg"
import Img22 from "../Images/sportsday1.jpg"
import Img23 from "../Images/sportsday2.jpg"
import Img24 from "../Images/sportsday3.jpg"
import Img7 from "../Images/VanMahotsav1.jpg"
import Img8 from "../Images/VanMahotsav2.jpg"
import Img9 from "../Images/VanMahotsav4.jpg"
import Img10 from "../Images/VanMahotsav3.jpg"
import Img11 from "../Images/VanMahotsav5.jpg"
import React, { useEffect, useState } from 'react'

export default function Activity() {
  //eslint-disable-next-line
  const [slideIndex, setSlideIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => { setSlideIndex(prevIndex => (prevIndex + 1) % 3); }, 4200);
    return () => { clearInterval(interval); };
  }, []);
  return (
    <>
      <div className="container items-center text-center px-6 py-12 mx-auto">
        <div>
          <motion.h1 initial={{ opacity: 0, x: -15, y: 5 }} whileInView={{ opacity: 1, y: 0, x: 0 }} transition={{ duration: 0.7 }} className="mt-2 text-2xl font-semibold text-black md:text-3xl" style={{ fontSize: "45px", fontFamily: "Lemonada", fontWeight: "700" }}>Activity</motion.h1>
          <motion.p initial={{ opacity: 0, x: -15, y: 5 }} whileInView={{ opacity: 1, y: 0, x: 0 }} transition={{ duration: 0.8, delay: 0.7 }} viewport={{ once: true }} className="py-5 mt-3 text-black" style={{ fontSize: "35px", fontFamily: "Lemonada", fontWeight: "500" }}>Our curriculum is designed to stimulate your child's imagination, promote critical thinking, and lay a strong foundation for their future academic success...</motion.p>
        </div>
      </div>
      <section class="px-4 md:px-11">
        <UnevenGrid img1={Img} img2={Img1} img3={Img2} img4={Img3} img5={Img4} img6={Img5} />
      </section>
      <section class="py-20">
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-col text-center w-full mb-20">
            <motion.h1 initial={{ opacity: 0, x: -20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.6 }} class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900" style={{ fontSize: "45px", fontFamily: "Lemonada", fontWeight: "700" }}>Good old Hide & Seek</motion.h1>
          </div>
          <EvenGrid img1={Img12} img2={Img13} img3={Img14} />
        </div>
      </section>
      <section class="py-12">
        <div class="container px-5 mx-auto flex flex-wrap">
          <div class="flex w-full mb-20 flex-wrap">
            <motion.h1 initial={{ opacity: 0, x: -20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }} class="sm:text-2xl font-medium title-font text-gray-900 lg:w-1/3 lg:mb-0 mb-4 md:text-5xl" style={{ fontSize: "45px", fontFamily: "Lemonada", fontWeight: "700" }}>VanMahotsav Day at Shishu Mangal School</motion.h1>
            <motion.p initial={{ opacity: 0, x: 20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.6, delay: 0.5 }} class="lg:pl-6 lg:w-2/3 mx-auto leading-relaxed text-base" style={{ fontSize: "25px", fontFamily: "Lemonada", fontWeight: "700" }}> Where children did pre writing skills such as:
              <ul style={{ fontSize: "17px", listStyle: "disc", paddingLeft: "40px" }}>
                <motion.li initial={{ opacity: 0, x: 20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.6 }}>Brush painting to depict the green grass.</motion.li>
                <motion.li initial={{ opacity: 0, x: 20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.6, delay: 0.5 }}>Finger painting for the spray if water from. the caused for  watering the plants, Making it a daily routine.</motion.li>
                <motion.li initial={{ opacity: 0, x: 20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.6, delay: 0.7 }}>Rhymes with action on how to sow seeds.</motion.li>
              </ul>
            </motion.p>
          </div>
          <UnevenGrid img1={Img6} img2={Img7} img3={Img9} img4={Img8} img5={Img10} img6={Img11} />
        </div>
      </section>
      <section class="">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          <div class="flex w-full mb-20 flex-wrap">
            <motion.h1 initial={{ opacity: 0, x: -20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }} class="sm:text-2xl font-medium title-font text-gray-900 lg:w-1/3 lg:mb-0 mb-4 md:text-5xl" style={{ fontSize: "45px", fontFamily: "Lemonada", fontWeight: "700" }}>The famous Winter Carnival of Shishu Mangal School</motion.h1>
          </div>
          <UnevenGrid img1={Img15} img2={Img16} img3={Img17} img4={Img18} img5={Img19} img6={Img20} />
        </div>
      </section>
      <section>
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-col text-center w-full mb-20">
            <motion.h1 initial={{ opacity: 0, x: -20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.6 }} class="sm:text-3xl md:text-6xl text-6xl font-medium title-font mb-4 text-gray-900" style={{ fontSize: "45px", fontFamily: "Lemonada", fontWeight: "700" }}>Children's Day as Sports Day at Shishu Mangal.</motion.h1>
          </div>
          <div class="flex flex-wrap -m-4">
            <div class="lg:w-1/3 sm:w-1/2 p-4">
              <div class="flex relative">
                <motion.img initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} whileHover={{ scale: 1.01 }} transition={{ duration: 0.6 }} alt="" class="absolute inset-0 w-full h-full object-cover object-center" height={"600px"} width={"360px"} src={Img21} />
                <div class="px-8 py-10 relative z-10 w-full border-4 opacity-0">
                  <h1 class="title-font text-lg font-medium text-gray-900 mb-3">Shooting Stars</h1>
                  <p class="leading-relaxed">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>
                </div>
              </div>
            </div>
            <div class="lg:w-1/3 sm:w-1/2 p-4">
              <div class="flex relative">
                <motion.img initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} whileHover={{ scale: 1.01 }} transition={{ duration: 0.6, delay: 0.3 }} alt="" class="absolute inset-0 w-full h-full object-cover object-center" height={"601px"} width={"361px"} src={Img22} />
                <div class="px-8 py-10 relative z-10 w-full border-4 opacity-0">
                  <h1 class="title-font text-lg font-medium text-gray-900 mb-3">The Catalyzer</h1>
                  <p class="leading-relaxed">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>
                </div>
              </div>
            </div>
            <div class="lg:w-1/3 sm:w-1/2 p-4">
              <div class="flex relative">
                <motion.img initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} whileHover={{ scale: 1.01 }} transition={{ duration: 0.6, delay: 0.5 }} alt="" class="absolute inset-0 w-full h-full object-cover object-center" height={"605px"} width={"365px"} src={Img23} />
                <div class="px-8 py-10 relative z-10 w-full border-4 opacity-0">
                  <h1 class="title-font text-lg font-medium text-gray-900 mb-3">The 400 Blows</h1>
                  <p class="leading-relaxed">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap -m-4">
            <div class="lg:w-1/3 sm:w-1/2 p-4">
              <div class="flex relative">
                <motion.img initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} whileHover={{ scale: 1.01 }} transition={{ duration: 0.6 }} alt="" class="absolute inset-0 w-full h-full object-cover object-center" height={"600px"} width={"360px"} src={""} style={{ display: "none" }} />
              </div>
            </div>
            <div class="lg:w-1/3 sm:w-1/2 p-4">
              <div class="flex relative">
                <motion.img initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} whileHover={{ scale: 1.01 }} transition={{ duration: 0.6, delay: 0.3 }} alt="" class="absolute inset-0 w-full h-full object-cover object-center" height={"601px"} width={"361px"} src={Img24} />
                <div class="px-8 py-10 relative z-10 w-full border-4 opacity-0">
                  <h1 class="title-font text-lg font-medium text-gray-900 mb-3">The Catalyzer</h1>
                  <p class="leading-relaxed">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>
                </div>
              </div>
            </div>
            <div class="lg:w-1/3 sm:w-1/2 p-4">
              <div class="flex relative">
                <motion.img initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} whileHover={{ scale: 1.01 }} transition={{ duration: 0.6, delay: 0.5 }} alt="" class="absolute inset-0 w-full h-full object-cover object-center" height={"603px"} width={"363px"} src={""} style={{ display: "none" }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="">
        <div class="container px-5 py-20 mx-auto flex flex-wrap">
          <div class="flex w-full mb-20 flex-wrap">
            <motion.h1 initial={{ opacity: 0, x: -20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }} class="sm:text-2xl font-medium title-font text-gray-900 lg:w-1/3 lg:mb-0 mb-4 md:text-5xl" style={{ fontSize: "45px", fontFamily: "Lemonada", fontWeight: "700" }}>Activity based... Regular Parents Teachers Day at Shishu Mangal</motion.h1>
            <motion.p initial={{ opacity: 0, x: 20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.6, delay: 0.5 }} class="lg:pl-6 lg:w-2/3 mx-auto leading-relaxed text-base" style={{ fontSize: "25px", fontFamily: "Lemonada", fontWeight: "700" }}> The syllabus is covered gradually through well planned indoor and outdoor activities.</motion.p>
          </div>
          <UnevenGrid img1={Img25} img2={Img27} img3={Img26} img4={Img29} img5={Img28} img6={Img30} />
        </div>
      </section>
      <section class="">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          <div class="flex w-full mb-20 flex-wrap">
            <motion.h1 initial={{ opacity: 0, x: -20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }} class="sm:text-2xl font-medium title-font text-gray-900 lg:w-2/3 md:w-2/3 lg:mb-0 mb-5 md:text-5xl" style={{ fontSize: "45px", fontFamily: "Lemonada", fontWeight: "700" }}>All Religious and National Festival is a fun learning celebration for the little ones...</motion.h1>
          </div>
          <UnevenGrid img1={Img31} img2={Img32} img3={Img35} img4={Img36} img5={Img33} img6={Img34} />
        </div>
      </section>
      <section class="">
        <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
          <div class="text-center lg:w-2/3 w-full">
            <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900" style={{ fontFamily: "Poppins", fontSize: "50px", padding: "30px", fontWeight: "500" }}>Admissions Open!!</h1>
          </div>
          <center>
            <iframe width="500" height="500" src="https://www.youtube.com/embed/7iMkeppk8a8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </center>
        </div>
      </section>
    </>
  )
}

const UnevenGrid = ({ img1, img2, img3, img4, img5, img6 }) => {
  return (
    <div class="flex flex-wrap md:-m-2 -m-1">
      <div class="flex flex-wrap w-full md:w-1/2">
        <div class="md:p-2 p-1 w-1/2">
          <motion.img initial={{ opacity: 0, y: 5 }} whileInView={{ opacity: 1, y: 0 }} whileHover={{ scale: 1.02 }} transition={{ duration: 0.6 }} viewport={{ once: true }} alt="" class="w-full object-cover h-full object-center block" src={img1} />
        </div>
        <div class="md:p-2 p-1 w-1/2">
          <motion.img initial={{ opacity: 0, y: 5 }} whileInView={{ opacity: 1, y: 0 }} whileHover={{ scale: 1.02 }} transition={{ duration: 0.6, delay: 0.3 }} viewport={{ once: true }} alt="" class="w-full object-cover h-full object-center block" src={img2} />
        </div>
        <div class="md:p-2 p-1 w-full">
          <motion.img initial={{ opacity: 0, y: 5 }} whileInView={{ opacity: 1, y: 0 }} whileHover={{ scale: 1.02 }} transition={{ duration: 0.6, delay: 0.5 }} viewport={{ once: true }} alt="" class="w-full h-full object-cover object-center block" src={img3} />
        </div>
      </div>
      <div class="flex flex-wrap w-full md:w-1/2">
        <div class="md:p-2 p-1 w-full">
          <motion.img initial={{ opacity: 0, y: 5 }} whileInView={{ opacity: 1, y: 0 }} whileHover={{ scale: 1.02 }} transition={{ duration: 0.6, delay: 0.7 }} viewport={{ once: true }} alt="" class="w-full h-full object-cover object-center block" src={img4} />
        </div>
        <div class="md:p-2 p-1 w-1/2">
          <motion.img initial={{ opacity: 0, y: 5 }} whileInView={{ opacity: 1, y: 0 }} whileHover={{ scale: 1.02 }} transition={{ duration: 0.6, delay: 0.9 }} viewport={{ once: true }} alt="" class="w-full object-cover h-full object-center block" src={img5} />
        </div>
        <div class="md:p-2 p-1 w-1/2">
          <motion.img initial={{ opacity: 0, y: 5 }} whileInView={{ opacity: 1, y: 0 }} whileHover={{ scale: 1.02 }} transition={{ duration: 0.6, delay: 1.1 }} viewport={{ once: true }} alt="" class="w-full object-cover h-full object-center block" src={img6} />
        </div>
      </div>
    </div>
  )
}

const EvenGrid = ({ img1, img2, img3 }) => {
  return (
    <div class="flex flex-wrap -m-4">
      <div class="lg:w-1/3 sm:w-1/2 p-4">
        <div class="flex relative">
          <motion.img initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} whileHover={{ scale: 1.01 }} transition={{ duration: 0.6 }} alt="" class="absolute inset-0 w-full h-full object-cover object-center" height={"600px"} width={"360px"} src={img1} />
          <div class="px-8 py-10 relative z-10 w-full border-4 opacity-0">
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">Shooting Stars</h1>
            <p class="leading-relaxed">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>
          </div>
        </div>
      </div>
      <div class="lg:w-1/3 sm:w-1/2 p-4">
        <div class="flex relative">
          <motion.img initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} whileHover={{ scale: 1.01 }} transition={{ duration: 0.6, delay: 0.3 }} alt="" class="absolute inset-0 w-full h-full object-cover object-center" height={"601px"} width={"361px"} src={img2} />
          <div class="px-8 py-10 relative z-10 w-full border-4 opacity-0">
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">The Catalyzer</h1>
            <p class="leading-relaxed">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>
          </div>
        </div>
      </div>
      <div class="lg:w-1/3 sm:w-1/2 p-4">
        <div class="flex relative">
          <motion.img initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} whileHover={{ scale: 1.01 }} transition={{ duration: 0.6, delay: 0.5 }} alt="" class="absolute inset-0 w-full h-full object-cover object-center" height={"603px"} width={"363px"} src={img3} />
          <div class="px-8 py-10 relative z-10 w-full border-4 opacity-0">
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">The 400 Blows</h1>
            <p class="leading-relaxed">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>
          </div>
        </div>
      </div>
    </div>
  )
}
