import '../Styles/Home.css'
import { motion } from 'framer-motion'
import BgImage from "../Images/home.jpg"
import profpic from "../Images/prof.png";
import Image2 from "../Images/home1.jpg";
import Image3 from "../Images/home2.jpg";
import Image4 from "../Images/home3.jpg";
import Image5 from "../Images/home4.jpg";
import Image6 from "../Images/home5.jpg";
import Image7 from "../Images/home6.jpg";
import Image8 from '../Images/Home8.jpg';
import React, { useState, useEffect } from 'react'

export default function Home() {
  const Images = [Image2, Image3, Image4, Image5, Image6, Image7];
  return (
    <>
      <section className='Imgbg'>
        <motion.img initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.7 }} src={BgImage} alt="" className="background-image" />
        <div className="diff container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-center md:text-left items-center text-center">
            <motion.h1 className="imgtext title-font text-6xl 2xl:text-7xl 2xl:font-semibold mb-4 font-medium text-white" initial={{ opacity: 0, y: 25 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }} style={{ fontFamily: "Poppins", fontWeight: "550" }}>A Pre-School where<br />
              &nbsp;<br className="hidden lg:inline-block" />&nbsp;Learning is Joyous and Adventurous
            </motion.h1>
          </div>
        </div>
      </section>
      <br />
      <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <motion.img initial={{ opacity: 0, x: -10 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 1 }} className="object-cover object-center rounded" src={Image8} alt="" style={{ borderRadius: "30px" }} />
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-center md:text-center items-center text-center">
            <motion.h1 initial={{ opacity: 0, y: -30 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.35 }} style={{ fontFamily: "Lemonada", fontWeight: "800", }} className="imgtext title-font text-6xl mb-4 text-center font-medium text-gray-900 py-7">Welcome to Shishu Mangal School,
              <br className="hidden lg:inline-block" />
            </motion.h1>
            <motion.p initial={{ opacity: 0, y: -30 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.35, delay: 0.6 }} style={{ fontSize: "30px", fontWeight: "700", fontFamily: "Lemonada" }} className="mb-8 leading-relaxed p-3 text-center text-black">Where we believe in nurturing young minds through a broad and balanced curriculum. <br />Discover a world of endless possibilities as your child embarks on an exciting educational journey with us.</motion.p>
          </div>
        </div>
      </section>
      <br />
      <div>
        <h1 className='p-6 py-2 text-center items-center' style={{ fontSize: "35px", fontWeight: "700", fontFamily: "Poppins", fontSmooth: "auto" }}>Our curriculum is designed to stimulate your child's imagination, promote critical thinking, and lay a strong foundation for their future academic success...</h1>
        <Slideshow images={Images} interval={3500} />
      </div>
      <div className="container py-20 mx-auto items-center">
        <motion.h1 initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className="text-3xl font-medium title-font text-gray-900 mb-12 text-center items-center" style={{ fontFamily: "Poppins", fontWeight: "700", fontSize: "45px" }}>Testimonials</motion.h1>
        <br />
        <div className="flex flex-wrap -m-4">
          <TestimonialBlock user={"Mayank Arya"} testimonial={"One of the finest play schools. I have my younger sibling going to Shishu Mangal School. He is found to be creative and more respectul towards his elders. Good environment.👍👍"} />
          <TestimonialBlock user={"Nishu Malik"} testimonial={"Awesome pre school. Both of my kids have  done their  pre school from there. This pre school has the best teachers. They have a unique style to teach children. According to my opinion this is the best pre school in east Delhi."} />
        </div>
      </div>
      <div className="container p-5 items-center">
        <div className="flex flex-wrap -m-4 ">
          <TestimonialBlock user={"Chakrapani Patra"} testimonial={"The Best in East Delhi and may be in Delhi as well for nurturing kids at their tender age. Very well managed by Delhi Gujarati Mahila Samaj. I had the privilege of having both my kids join there. Can't imagine it from any other private schools. Absolutely amazing staff taking best if care of kids there with loads of activities. Great keep it up. I'm writing this review after 8 years but feel so good to share my experience here. Wishing all the staff and trustees of the school and thankful to them for their contributions to numerous kids there. Namaste 🙏"} />
          <TestimonialBlock user={"Chandrika Patel"} testimonial={"The best school in East Delhi for kids. School is wonderfully built. Teachers are supportive and caring. I would like to suggest people to send their kids for growth."} />
        </div>
      </div>
      <div className="container p-5 items-center">
        <div className="flex flex-wrap -m-4 ">
          <TestimonialBlock user={"Kanika Singh"} testimonial={"This school is really good school for a child's development. The teachers offer interesting activities for our child's overall development."} />
          <TestimonialBlock user={"Heena Gupta"} testimonial={"Shishu mangal School is the best pre-school in East Delhi. The teachers are extremely supportive & the environment is amazing. Children are taught in really fun ways."} />
        </div>
      </div>
    </>
  )
}

const Slideshow = ({ images, interval }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => { setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length); }, interval);
    return () => clearInterval(timer);
  }, [images.length, interval]);
  return (
    <div className="slideshow py-4 items-center justify-center">
      <div className="slideshow-image-container py-5">
        {images.map((image, index) => (
          <img key={index} src={image} alt="" className={`slideshow-image ${index === currentIndex ? 'active' : ''}`} />
        ))}
      </div>
    </div>
  );
};

export const TestimonialBlock = ({ testimonial, user }) => {
  return (
    <motion.div className="p-4 md:w-1/2 w-full justify-between" initial={{ opacity: 0, scale: 0 }} whileInView={{ opacity: 1, scale: 1 }} whileHover={{ scale: 1.03 }} transition={{ duration: 0.7 }}>
      <div className="h-full bg-gray-100 p-8 rounded" style={{ borderRadius: "16px" }}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="block w-5 h-5 text-gray-400 mb-4" viewBox="0 0 975.036 975.036">
          <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
        </svg>
        <p className="leading-relaxed mb-6">{testimonial}</p>
        <div className="container" style={{ display: "flex" }}>
          <div className="row1">
            <img className="rounded-full flex-shrink-0 object-cover object-center" src={profpic} height={"20px"} width={"20px"} alt="" />
          </div>&nbsp;&nbsp;
          <div className="row2">
            <span className="title-font font-medium text-gray-900">{user}</span>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
