import React from 'react'
import logo from '../Images/favicon.png'
import { Link } from 'react-router-dom/cjs/react-router-dom'

export default function Footer() {
    return (
        <div>
            <footer class="px-5 py-24 flex md:flex-row md:flex-nowrap justify-between items-center">
                <div class="w-1/2 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                    <Link to="/" class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
                        <img src={logo} alt="" height={"65px"} width={"65px"} />
                        <span class="ml-3 text-xl" style={{ fontFamily: "poppins", fontWeight: "600" }}>Shishu Mangal School</span>
                    </Link>
                </div>
                <div class="flex -mb-10 md:mt-0 mt-10 justify-between">
                    <div className="w-2/3 px-4">
                        <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3" style={{ fontFamily: "poppins", fontWeight: "600", fontSize: "20px" }}>Contact Us</h2>
                        <nav className="list-none mb-10 py-2 items-center text-center">
                            <li>
                                <div className="flex items-center py-2">
                                    <i className="fa-solid fa-phone"></i>&nbsp;&nbsp;
                                    <h1 className="text-gray-600 hover:text-gray-800">+91 9313632866</h1>
                                </div>
                            </li>
                            <li>
                                <div className="flex items-center py-2">
                                    <i className="fa-solid fa-phone"></i>&nbsp;&nbsp;
                                    <h1 className="text-gray-600 hover:text-gray-800">011 45040152</h1>
                                </div>
                            </li>
                            <li>
                                <div className="flex items-center py-2">
                                    <i className="fa-regular fa-envelope"></i>&nbsp;&nbsp;
                                    <a className="text-gray-600 hover:text-gray-800" href='mailto:mangalshishu@yahoo.in'>mangalshishu@yahoo.in</a>
                                </div>
                            </li>
                            <li>
                                <div className="flex items-center py-2 text-center">
                                    <i className="fa-regular fa-clock"></i>&nbsp;&nbsp;
                                    <h1 className="text-gray-600 hover:text-gray-800">Monday to Saturday<br />(9:00am to 1:30pm)</h1>
                                </div>
                            </li>
                            <li>
                                <div className="flex items-center py-2">
                                    <i className="fa-regular fa-clock"></i>&nbsp;&nbsp;
                                    <h1 className="text-gray-600 hover:text-gray-800">Sunday: Closed</h1>
                                </div>
                            </li>
                        </nav>
                    </div>
                    <div className="w-full px-4 w-1/3">
                        <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3 text-end" style={{ fontFamily: "poppins", fontWeight: "600", fontSize: "20px" }}>Useful Links</h2>
                        <nav className="list-none mb-10 py-2 items-center text-end">
                            <li>
                                <div className="py-2">
                                    <Link to="/" className="text-gray-600 hover:text-gray-800">Home</Link>
                                </div>
                            </li>
                            <li>
                                <div className="py-2">
                                    <Link to="/about" className="text-gray-600 hover:text-gray-800">About Us</Link>
                                </div>
                            </li>
                            <li>
                                <div className="py-2">
                                    <Link to="/activity" className="text-gray-600 hover:text-gray-800">Activities</Link>
                                </div>
                            </li>
                            <li>
                                <div className="py-2">
                                    <Link to="/faculty" className="text-gray-600 hover:text-gray-800">Faculty</Link>
                                </div>
                            </li>
                            <li>
                                <div className="py-2">
                                    <Link to="/contact" className="text-gray-600 hover:text-gray-800">Contact Us</Link>
                                </div>
                            </li>
                        </nav>
                    </div>
                </div>
            </footer>
            <footer className="text-gray-600 body-font">
                <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
                    <Link to="/" className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
                        <span className="ml-3 text-xl">Shishu Mangal School</span>
                    </Link>
                    <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
                        <a href="https://www.facebook.com/shishumangalschool/" className="text-gray-500 hover:text-black">
                            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                            </svg>
                        </a>
                        <Link className="ml-3 text-gray-500 hover:text-black">
                            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                            </svg>
                        </Link>
                        <Link className="ml-3 text-gray-500 hover:text-black">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                            </svg>
                        </Link>
                    </span>
                </div>
            </footer>
        </div>
    )
}
