import './App.css';
import './Styles/responsive.css'
import { useEffect } from 'react'
import Home from './Components/Home'
import About from './Components/About'
import logo from './Images/favicon.png'
import Footer from './Components/Footer'
import Contact from './Components/Contact'
import Faculty from './Components/Faculty'
import Activity from './Components/Activity'
import { BrowserRouter as Router, Route, Switch, Link, useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);
  return null;
};

export default function App() {
  return (
    <Router>
      <ScrollToTop />
      <header className="header1 sticky top-0 flex flex-wrap items-center justify-between px-4 md:px-8 py-2 bg-blue-900" style={{ opacity: "90", zIndex: 90 }}>
        <h1 className="w-full md:w-4/12 headingsms">
          <Link to="/">
            <div className="flex">
              <div className="flex p-0"><img src={logo} alt="" height={"40px"} width={"40px"} />&nbsp;&nbsp;</div><br />
              <div className="flex"><h1 className='text-white' style={{ fontWeight: "bold", fontSize: "x-large", fontFamily: "Poppins" }}>Shishu Mangal School</h1></div><br />
            </div>
          </Link>
        </h1>
        <nav className="nav font-semibold text-lg">
          <ul className="flex items-center">
            <li className="p-2 md:p-4 border-b-2 border-yellow-500 text-white border-opacity-0 hover:border-opacity-100 hover:text-yellow-500 duration-200 cursor-pointer active">
              <Link to='/' className="">Home</Link>
            </li>
            <li className="p-2 md:p-4 border-b-2 border-yellow-500 text-white border-opacity-0 hover:border-opacity-100 hover:text-yellow-500 duration-200 cursor-pointer">
              <Link to='/about' className="">About</Link>
            </li>
            <li className="p-2 md:p-4 border-b-2 border-yellow-500 text-white border-opacity-0 hover:border-opacity-100 hover:text-yellow-500 duration-200 cursor-pointer active">
              <Link to='/activity' className="">Activity</Link>
            </li>
            <li className="p-2 md:p-4 border-b-2 border-yellow-500 text-white border-opacity-0 hover:border-opacity-100 hover:text-yellow-500 duration-200 cursor-pointer">
              <Link to='/faculty' className="">Faculty</Link>
            </li>
            <li className="contactus p-2 md:p-4 border-b-2 border-yellow-500 text-white border-opacity-0 hover:border-opacity-100 hover:text-yellow-500 duration-200 cursor-pointer">
              <Link to='/contact' className="mr-5 ">Contact Us</Link>
            </li>
          </ul>
        </nav>
        <div className="w-3/12 flex justify-end">
          <svg className="h-6 md:h-8 p-1 hover:text-green-500 duration-200 svg-inline--fa fa-search fa-w-16 fa-9x"></svg>
          <svg className="h-6 md:h-8 p-1 hover:text-green-500 duration-200 svg-inline--fa fa-shopping-cart fa-w-18 fa-7x"></svg>
        </div>
      </header>
      <Switch>
        <Route exact path="/" render={() => { return (<Home />) }}></Route>
        <Route exact path="/faculty">
          <Faculty />
        </Route>
        <Route exact path="/activity">
          <Activity />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}