import emailjs from '@emailjs/browser'
import { motion } from 'framer-motion'
import React, { useRef, useState } from 'react';

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    user_email: '',
    phone: '',
    child: '',
    dob: '',
    message: ''
  });
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_56qbyca', 'template_z7g0266', form.current, 'Y5c4yb711nDhUKEtz')
      .then((result) => {
        console.log(result.text);
        setFormData({
          name: '',
          user_email: '',
          phone: '',
          child: '',
          dob: '',
          message: ''
        });
        setIsSubmitted(true);
      }, (error) => {
        console.log(error.text);
      });
  };
  const [setIsSubmitted] = useState(false);
  return (
    <motion.div className="mt-8 lg:w-1/2 lg:mx-6" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1.5 }}>
      <motion.div className="w-full px-8 py-10 mx-auto overflow-hidden shadow-2xl rounded-2xl lg:max-w-xl">
        <form ref={form} onSubmit={sendEmail} className="mt-4">
          <motion.div className="flex-1">
            <label className="block mb-2 text-sm text-gray-600 text-black">Full Name</label>
            <input required value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} type="text" name="name" placeholder="Enter Your Name" className="block w-full px-5 py-3 mt-2 text-black border border-gray-500 rounded-md bg-gray-100 text-black dark:border-gray-600 focus:border-gray-500 focus:ring-white focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
          </motion.div>
          <motion.div className="flex-1 mt-6">
            <label className="block mb-2 text-sm text-gray-600 text-black">Email address</label>
            <input required value={formData.user_email} onChange={(e) => setFormData({ ...formData, user_email: e.target.value })} type="email" name="user_email" placeholder="email@example.com" className="block w-full px-5 py-3 mt-2 text-black border border-gray-500 rounded-md bg-gray-100 text-black dark:border-gray-600 focus:border-gray-500 focus:ring-white focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
          </motion.div>
          <motion.div className="flex-1 mt-6">
            <label className="block mb-2 text-sm text-gray-600 text-black">Phone</label>
            <input required value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} type="tel" name="phone" placeholder="Phone" className="block w-full px-5 py-3 mt-2 text-black border border-gray-500 rounded-md bg-gray-100 text-black dark:border-gray-600 focus:border-gray-500 focus:ring-white focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
          </motion.div>
          <motion.div className="flex-1 mt-6">
            <label className="block mb-2 text-sm text-gray-600 text-black">Your Child's Name</label>
            <input required value={formData.child} onChange={(e) => setFormData({ ...formData, child: e.target.value })} type="text" name="name" placeholder="Name" className="block w-full px-5 py-3 mt-2 text-black border border-gray-500 rounded-md bg-gray-100 text-black dark:border-gray-600 focus:border-gray-500 focus:ring-white focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
          </motion.div>
          <motion.div className="flex-1 mt-6">
            <label className="block mb-2 text-sm text-gray-600 text-black">Your Child's DOB</label>
            <input required value={formData.dob} onChange={(e) => setFormData({ ...formData, dob: e.target.value })} type="date" name="name" placeholder="Enter the Date" className="block w-full px-5 py-3 mt-2 text-black border border-gray-500 rounded-md bg-gray-100 text-black dark:border-gray-600 focus:border-gray-500 focus:ring-white focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
          </motion.div>
          <motion.div className="w-full mt-6">
            <label className="block mb-2 text-sm text-gray-600 text-black">Message</label>
            <textarea required value={formData.message} onChange={(e) => setFormData({ ...formData, message: e.target.value })} name="message" className="block border border-black w-full h-32 px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 border border-grey-200 rounded-md md:h-48 bg-gray-100 text-black dark:border-gray-600 focus:border-gray-500 focus:ring-white focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" placeholder="Message"></textarea>
          </motion.div>
          <button type='submit' value="Send" style={{ fontFamily: "poppins" }} className="w-full px-6 py-3 mt-6 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-gray-500 focus:ring-white50">Get in Touch</button>
        </form>
      </motion.div>
    </motion.div>
  );
}

export default function Contact() {
  return (
    <>
      <section className='container items-center'>
        <div className="text-center px-6 py-12 mx-auto">
          <div>
            <motion.h1 initial={{ opacity: 0, x: -15 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.7 }} className="mt-2 text-2xl font-semibold text-black md:text-3xl" style={{ fontSize: "45px", fontFamily: "Poppins", fontWeight: "700" }}>Contact us</motion.h1>
            <motion.p initial={{ opacity: 0, x: -15 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.8, delay: 0.7 }} className="py-5 mt-3 text-black" style={{ fontSize: "20px", fontFamily: "Poppins" }}>Join our inclusive and vibrant preschool community, where your child's growth and happiness are at the heart of<br /> everything we do. Contact us today to schedule a visit, and witness firsthand the joy and excitement that awaits<br /> your child at Shishu Mangal School.<br /> Together, let's embark on a remarkable journey of learning and discovery.</motion.p>
          </div>
        </div>
        <motion.div className="items-center container px-5 py-18 mx-auto flex sm:flex-nowrap flex-wrap" style={{ paddingLeft: "185px" }}>
          <motion.div className="h-[30rem] w-[35rem] lg:w-5/6 md:w-2/3 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-center relative">
            <motion.iframe initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.6 }} width="100%" height="100%" className="absolute inset-0 items-center" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="yes" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.9002025948694!2d77.28401827474205!3d28.632752975664605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfb5077a99909%3A0x195ee8f93f4f28ba!2sSHISHU%20MANGAL%20SCHOOL!5e0!3m2!1sen!2sin!4v1684664638768!5m2!1sen!2sin" style={{ contrast: "1.2", width: "100vw" }}></motion.iframe>
            <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
              <div className="lg:w-1/2 px-6">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
                <p className="mt-1">Plot No 1, Nirman Vihar, opposite Madhuban Park, Delhi,110092</p>
              </div>
              <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
                <a className="text-indigo-500 leading-relaxed" href="mailto:mangalshishu@yahoo.in">mangalshishu@yahoo.in</a>
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
                <p className="leading-relaxed">+91 9313632866</p>
                <p className="leading-relaxed">011 45040152</p>
                <p className="leading-relaxed"></p>
              </div>
            </div>
          </motion.div>
        </motion.div>
        <motion.div className="flex-1 lg:flex lg:-mx-6" style={{ paddingTop: "50px" }}>
          <motion.div className="text-white lg:w-1/2 lg:mx-6" style={{ paddingLeft: "15px", paddingTop: "50px" }}>
            <motion.h1 style={{ fontFamily: "poppins", fontSize: "47px" }} initial={{ opacity: 0, x: -10 }} whileInView={{ opacity: 1, x: 0 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }} className="md:text-6xl text-black font-semibold capitalize lg:text-3xl">Have any questions for us?</motion.h1>
            <motion.p style={{ fontFamily: "poppins", fontSize: "25px" }} initial={{ opacity: 0, x: -10 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5, delay: 0.3 }} className="max-w-xl text-black mt-6">We would love to hear from you</motion.p>
          </motion.div>
          <ContactForm />
        </motion.div>
      </section>
    </>
  )
}