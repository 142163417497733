import React from 'react'
import { motion } from 'framer-motion'
import About1 from "../Images/Aboutimg.png";
import About2 from "../Images/Aboutimg1.png";
import About3 from "../Images/Aboutimg2.png";
import About4 from "../Images/Aboutimg3.jpg";

export default function About() {
  return (
    <>
      <motion.section className="py-7">
        <motion.div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <motion.div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <motion.img className="object-cover object-center rounded" alt="" src={About2} style={{ borderRadius: "30px" }} />
          </motion.div>
          <motion.div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }} style={{ fontFamily: "Poppins", fontWeight: "600" }} className="title-font text-center items-center sm:text-4xl text-3xl mb-4 font-medium text-gray-900">About Us</motion.h1>
            <p className="mb-8 leading-relaxed text-black" style={{ fontFamily: "Poppins", fontSize: "20px" }}>Shishu Mangal School was established in 1986 by Shree Delhi Gujarati Mahila Samaj. We aim to provide a high quality pre-school learning environment to our students. Nurturing and protecting childhood in a warm, spacious, and in a home-like surrounding is the key element for effective learning. <br /><br /> With the Support of Shree Delhi Gujarati Mahila Samaj we aspire to create an exceptional preschool experience that prepares children for a successful future while nurturing their innate curiosity, creativity, and love for learning.</p>
          </motion.div>
        </motion.div>
      </motion.section>
      <br />
      <section className="">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <motion.h1 initial={{ opacity: 0, x: -25 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.8 }} className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900" style={{ fontFamily: "Poppins", fontWeight: "700" }}>Our Vision</motion.h1>
            <motion.p initial={{ opacity: 0, x: -25 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5, delay: 0.6 }} style={{ fontSize: "20px", fontFamily: "Poppins" }} className="text-black leading-relaxed mb-8">We believe that learning should be a joyful and enriching experience. Our vision is to create a preschool where children are excited to explore, discover, and engage in hands-on activities. We aim to foster a love for learning that extends beyond the preschool years.</motion.p>
          </div>
          <motion.div initial={{ opacity: 0, x: 10 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 1, delay: 0.5 }} className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img className="object-cover object-center rounded" alt="" src={About1} height={"850px"} width={"700px"} style={{ borderRadius: "35px" }} />
          </motion.div>
        </div>
      </section>
      <section className="">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <motion.img className="object-cover object-center rounded" alt="" src={About4} style={{ borderRadius: "30px" }} />
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <motion.h1 initial={{ opacity: 0, x: 25 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.8 }} className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900" style={{ fontFamily: "Poppins", fontWeight: "600" }}>Partnership with Families</motion.h1>
            <motion.p initial={{ opacity: 0, x: 25 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.8, delay: 0.5 }} className="mb-8 leading-relaxed text-black" style={{ fontSize: "20px", fontFamily: "Poppins" }}>A strong partnership between the preschool and families is vital for a child's success. Our vision is to establish open and transparent communication with families, involving them in their child's educational journey and providing resources and support.</motion.p>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <motion.h1 initial={{ opacity: 0, x: 25 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }} className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900" style={{ fontSize: "37px", fontFamily: "Poppins", fontWeight: "700" }}>Our Approach</motion.h1>
            <motion.p className="mb-8 text-black leading-relaxed" initial={{ opacity: 0, x: -25 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5, delay: 0.6 }} style={{ fontSize: "20px", fontFamily: "Poppins" }}>We provide a flexible and Scientific approach in order to enhance the teaching process.</motion.p>
            <motion.p className="mb-8 text-black leading-relaxed" initial={{ opacity: 0, x: -25 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5, delay: 0.8 }} style={{ fontSize: "20px", fontFamily: "Poppins" }}>We recognize and celebrate the uniqueness of every child. Our vision is to create an inclusive environment that respects and embraces diversity, allowing each child to flourish and express their individuality.</motion.p>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img className="object-cover object-center rounded" src={About3} height={"850px"} width={"700px"} style={{ borderRadius: "30px" }} alt="" />
          </div>
        </div>
      </section>
    </>
  )
}
